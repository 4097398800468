import './css/fellowship-tailwind.css'

import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { TervButtonFilled } from 'components/TervButtons';
import CardSection from './cardSection';
import ApplyButton from './ApplyButton';
import Placements from './siva/Placement';
import ImmersiveProgram from './siva/ImmersiveProgramToLand';
import DownloadCariculam from './siva/DownloadCariculam';
import ApplyStartBox from './siva/Apply&StartForFreeBox';
import PersonalisedPlacement from './siva/PersonalisedPlacement';
import AssuredReferrals from './siva/AssuredReferrals';
import RoadMap from './RoadMap';
import RoadMap_Sub from './siva/RoadMap_Sub'
import InterviewPreperation from './siva/InterviewPreparation';
import ProgramFee from './siva/ProgramFee';
import EmiBox from './siva/EmiBox';
import PriceTagComponent from './siva/PriceTag'
import TervAssuredBox from './siva/TervAssuredBox';
import JoinCommunity from './siva/JoinCommunityBox';
import CareerHandbook from './siva/DownloadCareerHadbook';
import RealWork from './siva/RealWork';
import TableComponent from './siva/TableBox';
import RecruitersComponent from './siva/RecruitersToo';
import FeedbackComponent from './siva/FeedbackComp';
import Faq from './siva/Faq';
import LongWayComp from './siva/LingWayComp';
import Slider from './siva/slider/slider'
import { toast } from 'react-toastify';
import { useState } from 'react';

const fellowShipProgram = () => {

  const [ phone, setPhone ] = useState("");

  const success = () =>{
    setPhone("");
    toast.info("Our Team will connect with you shortly", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  }


  return (
    <div className='bg-[#242145] w-full '>
      <div className='flex justify-center w-[80%] mx-auto mt-20 gap-10'>
        <div className='w-[60%] text-white'>
          <span className='text-[44px] font-bold leading-[1.25]' >
          {"Fellowship Program in Software Development"}
          </span>
          <p className='text-xl font-semibold mt-4' 
          style={
            {width :"75%"}
          }>
          {"Learn with Real Work-Ex. Unlock your path to leading product companies with real-world experience."}
          </p>
          <div className='w-full  text-white flex gap-20 justify-between'>
          <AssuredReferrals />
          </div>
        </div>
        <div id="form" className='w-[40%] text-white bg-[#474088] rounded-[32px] px-4 py-5 shadow-sm'>
          <div id="formheader" className='mb-4' style={{color : "white"}}>
              <span style={{
                fontSize:"24px",
                fontWeight:"800"
              }}>
              Kick Start Your Knowledge Quest 
              </span>
              <p style={{
                fontSize:"0.8rem",
                fontWeight:"400"}}>
                Unlock Success with Guidance and Clarity
             </p>
            </div>
            
            
            <div className="border border-gray-300 rounded-2xl p-2 mb-4 bg-white"> {/* Border and padding for each FormControl */}
                <FormControl className="w-full">
                    <InputLabel htmlFor="first-name">First Name</InputLabel>
                    <Input id="first-name" aria-describedby="first-name-helper" className="" disableUnderline />
                </FormControl>
            </div>

            <div className="border border-gray-300 rounded-2xl p-2 mb-4 bg-white">
            <FormControl className="w-full">
            <InputLabel htmlFor="last-name" className="text-tervGradiant">Last Name</InputLabel>
            <Input id="last-name" aria-describedby="last-name-helper" className="text-tervGradiant" disableUnderline />
            </FormControl>
            </div>

            <div className="border border-gray-300 rounded-2xl p-2 mb-4 bg-white"> 
            <FormControl className="w-full">
            <InputLabel htmlFor="email" className="text-tervGradiant">Email</InputLabel>
            <Input id="email" aria-describedby="email-helper" type="email" className="text-tervGradiant" disableUnderline />
            </FormControl>
            </div>

            <div className="border border-gray-300 rounded-2xl p-2 mb-4 bg-white"> 
            <FormControl className="w-full">
            <InputLabel htmlFor="mobile" className="text-tervGradiant">Mobile Number</InputLabel>
            <Input id="mobile" aria-describedby="mobile-helper" type="tel" className="text-tervGradiant" disableUnderline onChange={(e)=>setPhone(e.target.value)} />
            </FormControl>
            </div>

            <TervButtonFilled 
            type="submit" 
            className="bg-yellow-400 hover:bg-yellow-500 text-black w-full border"
            text='Book Your Trial'
            onClick={success}
            disabled={phone.length == 0}
            />
        </div>
      </div>
                <Placements />
        <div className='mb-10'>
            <h3 className='w-[60%] mx-auto mt-10 text-4xl text-center text-white font-bold leading-relaxed'>
                {"Experience-Driven Learning to Make You a Developer in Demand!"}
            </h3>
            <div className='bg-[#242145] mt-10'>
                <CardSection />
                <ApplyButton />
                {/* <Carousel /> */}
                <ImmersiveProgram />
                <DownloadCariculam />
                <LongWayComp />
                <RoadMap />
                <ApplyStartBox />
                <PersonalisedPlacement />
                <InterviewPreperation />
                <ApplyButton />
                <RoadMap_Sub />
                <ProgramFee />
                {/* <EmiBox /> */}
                <PriceTagComponent />
                <ApplyButton />
                <TervAssuredBox />
                <JoinCommunity />
                <CareerHandbook />
                <ApplyButton />
                <RealWork />
                <TableComponent />
                <RecruitersComponent />
                <ApplyButton />
                <FeedbackComponent />
                {/* <Slider /> */}
                <Faq />
            </div>
        </div>

    </div>
  );
}

export default fellowShipProgram;