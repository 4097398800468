import React from 'react';
import styles from "./employee.module.scss";
import safariBG from "../../../assets/safari.png";
import ThreeStepComponent from './ThreeStepComponent';

function ThreeStepAssessment() {
  return (
    <div>
        <div style={{backgroundImage: "linear-gradient(to top, rgba(121, 77, 253, 1), rgba(56, 21, 113, 1), rgba(27, 24, 52, 1))", padding: "30px", marginBottom:"70px"}}>
            <div className={styles.content__header__container} style={{paddingTop:"2%"}}>
                <p className={styles.content__header__title_wrap}>
                <span className={styles.content__header__sub}>Give us the haystack, we'll find you the </span>{" "}
                    <span className={styles.content__header__title}>sharpest needle!</span>
                </p>
            </div>
            <div style={{
                backgroundImage: `url(${safariBG})`, 
                backgroundSize:"90% 100%",
                minHeight : "550px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                padding:"20px",
                display:"flex",
                justifyContent:"center",
            }}>
                <div style={{width:"70%" , minHeight : "80%" , padding:"20px" , marginTop:"40px" }}>
                    <ThreeStepComponent />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ThreeStepAssessment