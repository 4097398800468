import React from 'react';
import { TextField, IconButton, Tooltip, InputAdornment, Snackbar } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MuiAlert from "@material-ui/lab/Alert";

function FieldCopy({ link }) {
    const [toast, setToast] = React.useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(link);
        setToast(true);
    };

    return (
        <>
        <Snackbar
          open={toast}
          autoHideDuration={5000}
          onClose={() => setToast(!toast)}
        >
          <MuiAlert
            variant="filled"
            elevation={6}
            onClose={() => setToast(!toast)}
            severity="info"
          >
          {"Link copied to clipboard!"}
          </MuiAlert>
        </Snackbar>
        <TextField
            value={link}
            fullWidth
            label="Assessment Link"
            variant="outlined"
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title="Copy link">
                            <IconButton onClick={handleCopy}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
        />
        </>

    );
}

export default FieldCopy;
