import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

function SkillsTableComponent({
    skillList = [],
    setSkillList,
    index,
}) {
    const [data, setData] = useState({
        skill : '',
        testType : '',
        easy : 0,
        medium : 0,
        hard : 0,
    });

    const testType = [
        {label : "Coding" , value : "CD"},
        {label : "Quiz" , value : "MC"}
    ]

    useEffect(() => {
        setSkillList((prev) => {
            const newList = [...prev];
            newList[index] = data;
            return newList;
        });
    }, [data, index, setSkillList]);
  return (
    <div style={{display : "flex" , gap:"20px", justifyContent : "space-around" , alignItems : "center"}}>
        <div style={{width : "35%"}}>
            <Select 
                placeholder="Skill"
                options={skillList} 
                value={data.skill} 
                onChange={(val) => setData({...data, skill : val})}
            />
        </div>
        <div style={{width : "25%"}}>
            <Select 
                placeholder="Test Type"
                options={testType} 
                value={data.testType} 
                onChange={(val) => setData({...data, testType : val})}
            />
        </div>
        <div style={{width : "30%" , marginTop : "-30px" , textAlign : "center"}}>
            <span style={{width : "50%", margin: "auto" , marginBottom : "10px" , fontSize : "14px"}}>{"Question Distribution"}</span>
            <div style={{
                display : "flex" ,
                justifyContent : "space-around",
                gap:"10px" ,
                alignItems : "center",
                marginTop : "10px"
                }}>
                <TextField id="outlined-basic" label="Easy"  variant="outlined" type='number' size='small' style={{width : "30%"}} 
                    InputLabelProps={{
                        style: { fontSize: '14px', paddingLeft: '2px' }  // Adjust font size and left padding
                    }}
                    InputProps={{
                        style: { paddingLeft: '5px' }  // Adjust padding inside input field
                    }}
                    onChange={(e) => setData({...data, easy : Number(e.target.value)})}
                />
                <TextField id="outlined-basic" label="Medium" variant="outlined" type='number' size='small' style={{width : "30%"}}
                    InputLabelProps={{
                        style: { fontSize: '14px', paddingLeft: '2px', paddingRight : "2px" }  // Adjust font size and left padding
                    }}
                    InputProps={{
                        style: { paddingLeft: '2px' }  // Adjust padding inside input field
                    }}
                    onChange={(e) => setData({...data, medium : Number(e.target.value)})}
                />
                <TextField id="outlined-basic" label="Hard" variant="outlined" type='number'  size='small' style={{width : "30%"}}  
                    InputLabelProps={{
                        style: { fontSize: '14px', paddingLeft: '2px', paddingRight : "2px" }  // Adjust font size and left padding
                    }}
                    InputProps={{
                        style: { paddingLeft: '5px' }  // Adjust padding inside input field
                    }}
                    onChange={(e) => setData({...data, hard : Number(e.target.value)})}
                />
            </div>
        </div>
        <div style={{width : "10%"}}>
            <DeleteSweepIcon
                style={{cursor : "pointer"}}
            />
        </div>
    </div>
  )
}

export default SkillsTableComponent